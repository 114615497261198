import React from "react"
import MainLayout from "../components/MainLayout"
import HeroImage from "../images/kodiak-cover-shot-resized.jpg"
import styled from "styled-components"
import SEO from "../components/seo"

const CleaningStyles = styled.div`
  .hero-image {
    position: relative;
    width: 100%;
    height: 70%;
    margin-right: auto;
    margin-left: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  #cleaning-solution {
    position: absolute;
    color: white;
    width: 50%;
    top: 10%;
    background-color: rgba(0, 0, 0, 0.5);
    left: 50%;
    margin-left: -25%;
    text-align: center;

    h1 {
      font-size: 6vw;
    }
  }

  #cleaning-content {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }

  .sub-heading {
  }

  p {
    line-height: 30px;
  }
`

const CleaningSolutionsPage = () => {
  return (
    <>
      <SEO
        title="Cleanliness during a Pandemic"
        description="A pandemic is the global outbreak of a disease. Classified as epidemics first, which is the spread of disease across a region."
      />
      <MainLayout>
        <CleaningStyles>
          <div className="hero-image">
            <img src={HeroImage} alt="" />
          </div>
          <div id="cleaning-solution">
            <h1>Cleanliness During a Pandemic</h1>
          </div>
          <div id="cleaning-content">
            <div className="section-header">
              <h2 className="sub-heading">What is a Pandemic?</h2>
              <p className="sub-heading">
                A pandemic is the global outbreak of a disease. Classified as
                epidemics first, which is the spread of disease across a region.
              </p>
              <h2 className="sub-heading">
                What does the CDC say about cleaning and disinfecting during a
                Pandemic?
              </h2>
              <h4>Cleaning</h4>
              <p className="sub-heading">
                “Refers to the removal of germs, dirt, and impurities from
                surfaces. Cleaning does not kill germs, but by removing them, it
                lowers their numbers and the risk of spreading infection.”
              </p>
              <h4>Disinfecting</h4>
              <p>
                “Refers to using chemicals to kill germs on surfaces. This
                process does not necessarily clean dirty surfaces or remove
                germs, but by killing germs on a surface after cleaning, it can
                further lower the risk of spreading infection.”
              </p>
              <h2>How do you kill a virus?</h2>
              <h4>How do you kill a virus?</h4>
              <p>
                A virus is any of a large group of submicroscopic infectious
                agents that are usually regarded as nonliving extremely complex
                molecules, that typically contain a protein coat surrounding an
                RNA or DNA core of genetic material but no semipermeable
                membrane, that are capable of growth and multiplication only in
                living cells, and that cause various important diseases in
                humans, animals, and plants.
              </p>
              <h4>Common products</h4>
              <p>
                The CDC recommends a few common products as disinfecting agents
                for killing viruses. A few of these include:
              </p>
              <ul>
                <li>Bleach Quaternary Disinfectant</li>
                <li>UV</li>
                <li>Lysol</li>
                <li>Pine cleaner</li>
                <li>Alcohol 60% and greater</li>
              </ul>
              <h2>Cleaning with Aqueous Ozone (AO)</h2>
              <h4>What is AO?</h4>
              <p>
                Aqueous Ozone (AO) utilizes the natural power of dissolved Ozone
                as an oxidizing agent to quickly clean surfaces and remove
                odors. AO is easy to use, created on demand, and requires no PPE
                during use.
              </p>
              <h4>How does it work on your equipment?</h4>
              <p>
                Our scrubbers are equipped with multiple corona generators as
                well as air driers to generate and produce Ozone. The Ozone is
                then drawn into the solution line and mixed with the fresh water
                right before the solution is dispensed to the ground. Once on
                the ground, the dissolved ozone oxidizes soils, germs and other
                contaminants and simply converts back to oxygen and water.
                Everything is recovered inside of our recovery tanks for
                effortless ease in removing soils.
              </p>
              <h4>AO as part of your Daily Cleaning Regiment</h4>
              <p>
                Prior to disinfecting an area, thoroughly clean all floor
                surfaces using your Kodiak floor scrubber with AO system turned
                on.
              </p>
            </div>
          </div>
        </CleaningStyles>
      </MainLayout>
    </>
  )
}

export default CleaningSolutionsPage
